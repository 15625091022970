@use '@angular/material' as mat;
@import 'colors';

@include mat.core();

$typography-config: mat.define-typography-config(
  $font-family: '"Poppins", sans-serif',
  $headline-1: mat.define-typography-level(57px, 64px, 400),
  $headline-2: mat.define-typography-level(45px, 52px, 400),
  $headline-3: mat.define-typography-level(36px, 44px, 400),
  $headline-4: mat.define-typography-level(32px, 40px, 400),
  $headline-5: mat.define-typography-level(28px, 36px, 400),
  $headline-6: mat.define-typography-level(24px, 32px, 400),
  $subtitle-1: mat.define-typography-level(22px, 28px, 400),
  $subtitle-2: mat.define-typography-level(16px, 24px, 500),
  $body-1: mat.define-typography-level(14px, 20px, 400),
  $body-2: mat.define-typography-level(14px, 20px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500),
);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette(mat.$indigo-palette),
      accent: mat.define-palette(mat.$pink-palette, A200, A100, A400),
    ),
    typography: $typography-config,
  )
);

@include mat.all-component-themes($my-theme);
@include mat.typography-hierarchy($typography-config);

.body-small {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
