// font size
$font-size-base: 1rem; // 16px
$font-size-xxs: $font-size-base * 0.5; // 8px
$font-size-xs: $font-size-base * 0.625; // 10px
$font-size-xs2: $font-size-base * 0.6875; // 11px
$font-size-sm: $font-size-base * 0.75; // 12px
$font-size-md: $font-size-base * 0.875; // 14px
$font-size-lg: $font-size-base * 1.125; // 18px
$font-size-xlg: $font-size-base * 1.25; // 20px

$h1-font-size: $font-size-base * 2.25; // 36px
$h2-font-size: $font-size-base * 2; // 32px
$h3-font-size: $font-size-base * 1.5; // 24px
$h4-font-size: $font-size-base * 1.25; // 20px
$h5-font-size: $font-size-base * 1.125; // 18px
$h6-font-size: $font-size-base * 0.875; // 14px

// screen size
$screen-tablet: 768px;
$screen-tablet-max: 990px;
$screen-desktop: 991px;
