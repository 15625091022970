@import 'colors';
@import 'variables';

.page {
  background-color: $white;
  padding: 32px;
  > .header {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    .icon {
      font-size: $font-size-md;
      height: $font-size-md;
      font-weight: bold;
      margin: 0 10px;
      color: $default-blue;
      cursor: pointer;
    }
    .text {
      font-size: $h3-font-size;
    }
  }

  .subtitle {
    margin-bottom: 32px;
  }

  .content {
    .form {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      .row {
        display: flex;
        margin-bottom: 32px;
      }

      .control {
        flex: 1;
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .buttons-block {
      margin-top: 32px;
      display: flex;
      app-button {
        &:not(:first-of-type) {
          margin-left: 20px;
        }
      }
    }
  }
}

.container {
  &-vertical,
  &-horizontal {
    display: flex;
    gap: 12px;
    > * {
      flex: 1;
    }
  }

  &-vertical {
    flex-direction: column;
  }

  &-horizontal {
    flex-direction: row;
  }
}

.cdk-overlay-container {
  .cdk-overlay-dark-backdrop {
    width: 100%;
    height: 100%;
    position: sticky;
  }
}
