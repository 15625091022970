$white: #ffffff;
$black: #000000;
$default-black: #1A1C1E;
$default-blue: #255CA3;

$default-blue-hovered: #4F7BB4;
$default-blue-pressed: #1E4B84;

$default-light-blue: #2F80ED;

$secondary-blue-hovered: #255CA31A;
$secondary-blue-pressed: #1E4B8433;

$info: #CBDCF1;
$success: #71CE62;
$error: #C70D0D;
$error-bg: #EB57571A;
$error-bg-2: #EB5757;
$warning: #F2994A;
$disabled: #AAAAAA;

$default-border: #DEE3EB;
$default-bg: #F3F3F4;

$default-gray: #42474E;

$default-box-shadow: #00000026;
