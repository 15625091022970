@import 'colors';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: $default-black;
}
.mat-typography {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: $default-black;
}

a {
  color: $default-blue;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: $default-blue-hovered;
  }

  &:active {
    color: $default-blue-pressed;
  }
}

// Angular material styles fix
.mat-mdc-tooltip .mdc-tooltip__surface {
  word-break: break-word !important;
}
.app-datepicker,
.app-dropdown,
.app-input {
  .mat-mdc-text-field-wrapper {
    height: 48px !important;
  }
}
.mat-mdc-text-field-wrapper {
  padding: 0 8px 0 16px !important;
}
.mat-mdc-form-field-infix {
  padding: 12px 0 !important;
  min-height: 48px !important;
}
.mat-mdc-form-field-icon-suffix > .mat-icon {
  padding: 12px 0 !important;
  color: $default-black !important;
}
.mat-mdc-dialog-surface {
  border-radius: 16px !important;
}

.mat-mdc-form-field {
  .mdc-notched-outline__leading {
    border-radius: 8px 0 0 8px !important;
    border-color: $default-border !important;
  }

  .mdc-notched-outline__trailing {
    border-radius: 0 8px 8px 0 !important;
    border-color: $default-border !important;
  }

  &.readonly {
    background-color: $default-bg;
    border-radius: 8px;
    border: 1px solid $default-bg;
  }

  &.mat-form-field-disabled {
    background-color: $default-bg;
    border-radius: 8px;
    border: 1px solid $default-bg;

    .mdc-text-field__input {
      color: $default-black !important;
    }

    .mat-mdc-select-disabled {
      color: $default-black;
    }
  }

  &.mat-focused {
    .mdc-notched-outline__leading {
      border-color: $default-blue !important;
    }
    .mdc-notched-outline__trailing {
      border-color: $default-blue !important;
    }
    .mat-mdc-form-field-icon-suffix {
      .mat-icon {
        color: $default-blue !important;
      }
    }
  }

  &.mat-form-field-invalid {
    .mdc-notched-outline__leading {
      border-color: $error !important;
    }
    .mdc-notched-outline__trailing {
      border-color: $error !important;
    }
    .mat-mdc-form-field-icon-suffix {
      .mat-icon {
        color: $error !important;
      }
    }
  }

  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    display: none;
  }

  .mdc-line-ripple {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
}

.mat-mdc-form-field-type-mat-select {
  .mat-mdc-text-field-wrapper.mdc-text-field {
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $default-border;
  }

  .mdc-text-field--disabled {
    background-color: $default-bg !important;
    border-radius: 8px;
    border: 1px solid $default-bg !important;
    color: $default-black !important;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: $white;
  }

  &.mat-focused {
    .mat-mdc-text-field-wrapper.mdc-text-field {
      border: 1px solid $default-blue;
    }
  }
  &.mat-form-field-invalid {
    .mat-mdc-text-field-wrapper.mdc-text-field {
      border: 1px solid $error;
    }
  }
  .mat-mdc-select-placeholder {
    color: $default-black;
  }
}

.mat-mdc-radio-button {
  .mdc-radio {
    padding: 6px 12px 6px 0 !important;

    .mat-ripple.mat-radio-ripple.mat-mdc-focus-indicator {
      display: none;
    }
  }
  .mdc-radio__background {
    &::before {
      background-color: $default-blue !important;
    }

    .mdc-radio__outer-circle {
      border-color: $default-blue !important;
    }
    .mdc-radio__inner-circle {
      border-color: $default-blue !important;
    }
  }
}

.mdc-switch__icons {
  display: none;
}

.mdc-switch__track {
  border-radius: 100px !important;
  height: 24px !important;
  border: 1px solid $default-border;

  &::before {
    background-color: $disabled !important;
  }
  &::after {
    background-color: $default-blue !important;
  }
}

.mdc-switch__handle {
  &::after {
    background-color: $white !important;
  }
}

.mdc-switch__ripple {
  display: none;
}

.mat-mdc-form-field-input-control {
  text-overflow: ellipsis;
}

.mat-mdc-option {
  .mdc-list-item__primary-text {
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
}

.w25perc {
  width: 25%;
}

.w40perc {
  width: 40%;
}

.w50perc {
  width: 50%;
}

.w60perc {
  width: 60%;
}

.w75perc {
  width: 75%;
}

.mat-subtitle-3 {
  font-weight: 500;
}

.mat-button-toggle-group {
  background-color: $default-bg;
  padding: 0.5rem;
  border-radius: 12px !important;
  border: none !important;
  .mat-button-toggle {
    border-radius: 8px !important;
  }
  .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    background-color: $white !important;
    color: $default-blue;
  }
  .mat-button-toggle-appearance-standard {
    background-color: $default-bg;
  }
}

.common-table {
  table {
    border: 1px solid $default-border;
    border-radius: 12px;
    overflow: hidden;
    white-space: nowrap;

    tr {
      font-size: 14px;
    }

    tbody {
      tr {
        font-size: 14px;

        &:hover {
          background-color: $default-bg;
        }
      }
    }

    td {
      width: 5rem;
      max-width: 20rem;
    }

    tr > td,
    tr > th {
      border-right: 1px solid $default-border;

      &:last-child {
        border-right: none;
      }
    }

    .table-action-icon {
      cursor: pointer;
      color: $default-blue;
    }

    .mat-column-status {
      text-align: center;
    }

    .mat-column-action {
      text-align: center;
      table {
        border: none;
        td {
          border: none;
          min-width: 30px;
        }
      }
    }

    .no-results-row {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 12px;
    }

    .no-results-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 12px;
    }
  }
}

app-radio {
  ._multiline {
    .mdc-radio {
      align-self: flex-start;
    }
    .mdc-form-field {
      margin-bottom: 12px;
    }
  }
}
